<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <h4 class="mr-sm-4 header-tablepage">
        {{ form.rqp_no }}
      </h4>
      <div class="title-tabs mt-3 d-flex justify-content-between">
        Detail
        <div
          class="d-flex align-items-center justify-content-md-end justify-content-center"
          style="column-gap: 10px; row-gap: 10px"
        >
          <div class="w-md-unset">
            สถานะ:
            <span :class="{ 'text-error': form.rqp_status_id == 1 }">{{
              form.rqp_status_name
            }}</span>
          </div>
          <div>
            อัพเดทล่าสุด:
            {{ this.form.update_time }}
          </div>
        </div>
      </div>
      <div class="p-3 bg-white">
        <div>
          <b-row>
            <b-col md="6">
              <InputText
                textFloat="เลขที่"
                placeholder="เลขที่"
                type="text"
                disabled
                v-model="form.rqp_no"
              ></InputText>
            </b-col>
            <b-col md="6">
              <InputText
                textFloat="วันที่"
                placeholder="วันที่"
                type="text"
                disabled
                :value="this.form.update_time"
              ></InputText>
            </b-col>

            <b-col cols="12">
              <div
                class="d-flex"
                v-for="item in sourceBranch"
                :key="item.label"
              >
                <p class="text-label f-bold">
                  {{ item.label }}
                </p>
                <p class="text-light-gray text-label">:</p>
                <p class="text-light-gray">&nbsp;{{ item.value || "-" }}</p>
              </div>
            </b-col>
            <b-col cols="12">
              <InputTextArea
                textFloat="หมายเหตุ"
                type="text"
                disabled
                class="custom-input"
                placeholder="-"
                rows="3"
                :value="form.note"
              ></InputTextArea>
            </b-col>
          </b-row>
        </div>
      </div>
      <div class="title-tabs">Product List</div>
      <div class="p-3 bg-white">
        <b-row class="align-items-center mb-2">
          <b-col>
            <div>สินค้าทั้งหมด {{ rows }} รายการ</div></b-col
          >
          <b-col>
            <b-input-group class="">
              <b-form-input
                placeholder="Search Product Name"
                v-model="filter.search"
                @keyup.enter="handleSearch"
              ></b-form-input>
              <b-input-group-append>
                <b-input-group-text>
                  <b-iconstack
                    font-scale="2"
                    type="submit"
                    @click.prevent="handleSearch"
                  >
                    <b-icon
                      stacked
                      icon="search"
                      scale="0.5"
                      variant="grey"
                    ></b-icon>
                  </b-iconstack>
                </b-input-group-text>
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>
        <b-table
          responsive
          striped
          hover
          show-empty
          empty-text="No matching records found"
          class="table-main table-custom"
          :no-border-collapse="false"
          :busy="isBusy"
          :items="items"
          :fields="fields"
        >
          <template v-slot:cell(index)="{ item }">
            {{ item.id_sort + 1 }}
          </template>

          <template v-slot:cell(product_action)="{ item }">
            <div
              v-if="item.rqp_action_type_id || item.rqp_action_type_id == 0"
              :class="
                item.rqp_action_type_id > 0 ? 'text-success' : 'text-error'
              "
            >
              {{ item.product_action }}
            </div>
            <div v-else class="d-flex justify-content-center">
              <div
                class="text-link text-gray mr-1"
                @click="openModalaction(1, item)"
              >
                Approve
              </div>
              <div
                class="text-link text-gray ml-1"
                @click="openModalaction(0, item)"
              >
                Reject
              </div>
            </div>
          </template>

          <template v-slot:cell(note)="{ item }">
            <InputText
              v-model="item.note"
              textFloat=""
              disabled
              type="text"
              placeholder="-"
            >
            </InputText>
          </template>
          <template v-slot:cell(headquarters_note)="{ item }">
            <InputText
              v-model="item.headquarters_note"
              textFloat=""
              disabled
              type="text"
              placeholder="-"
            >
            </InputText>
          </template>

          <template v-slot:cell(product_image)="{ item }">
            <img
              :src="item.product_image"
              class="img-size"
              v-if="item.product_image"
            />
          </template>
          <template v-slot:cell(quantity)="{ item }">
            <InputText
              v-model="item.quantity"
              textFloat=""
              disabled
              type="number"
              placeholder="-"
            >
            </InputText>
          </template>
          <template v-slot:cell(rqp_action_ref)="{ item }">
            <div>
              {{ item.rqp_action_ref || "-" }}
            </div>
          </template>
          <template v-slot:cell(po_no)="{ item }">
            <div
              v-if="item.po_no"
              :class="{ 'text-link': item.po_no }"
              @click="$router.push(`/purchase-order/detail/${item.po_id}`)"
            >
              {{ item.po_no }}
            </div>
            <div v-else>-</div>
          </template>
          <template v-slot:table-busy>
            <div class="text-center text-black my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong class="ml-2">Loading...</strong>
            </div>
          </template>
        </b-table>
        <Pagination
          @handleChangeTake="handleChangeTake"
          :pageOptions="pageOptions"
          :filter="filter"
          :rows="rows"
          @pagination="pagination"
        />
      </div>
    </div>
    <ModalActions
      ref="modalAction"
      :branchId="form.request_branch_id"
      @updateProduct="updateProduct"
    />
    <FooterAction
      @submit="confirmProductRequest()"
      @routePath="cancel()"
      backAction
    />
  </div>
</template>
<script>
import OtherLoading from "@/components/loading/OtherLoading";
import InputTextArea from "@/components/inputs/InputTextArea";
import ModalActions from "./components/ModalActions";

export default {
  components: {
    OtherLoading,
    InputTextArea,
    ModalActions,
  },
  data() {
    return {
      isLoading: true,
      isBusy: true,
      title: "",
      id: this.$route.params.id,
      form: {},
      sourceBranch: [
        {
          label: "ชื่อสาขา",
          value: "",
        },
        { label: "รหัส", value: "" },
        { label: "ประเภท", value: "" },
        { label: "ผู้ส่ง", value: "" },
        { label: "วันที่ส่งออก", value: "" },
      ],
      fields: [
        { label: "ลำดับ", key: "index" },

        { label: "รหัสสินค้า", key: "barcode" },
        {
          label: "Name",
          key: "product_name",

          thStyle: { width: "20%" },
        },
        { label: "Image", key: "product_image", thStyle: { width: "20%" } },
        { label: "หมายเหตุ(สาขา)", key: "note", thStyle: { width: "10%" } },
        {
          label: "หมายเหตุ(HQ)",
          key: "headquarters_note",
          thStyle: { width: "10%" },
        },
        { label: "Quantity", key: "quantity", thStyle: { width: "10%" } },

        { label: "Action", key: "product_action", thStyle: { width: "10%" } },
        {
          label: "หมายเลขใบโอน",
          key: "rqp_action_ref",
          thStyle: { width: "10%" },
        },
        {
          label: "หมายเลข PO",
          key: "po_no",
          thStyle: { width: "10%" },
        },
      ],
      filter: {
        search: "",
        page: 1,
        take: 10,
      },
      pageOptions: [
        { value: 5, text: "5 / page" },
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      items: [],
      rows: 0,
      allItem: [],
      filteredItems: [],
      searchDisplayProduct: "",
      productRequest: [],
      isEdit: false,
    };
  },
  async created() {
    await this.getData();
  },

  methods: {
    async getData() {
      this.isBusy = true;

      const res = await this.axios(`/rqp/${this.id}`);

      if (res.data.result == 1) {
        this.form = res.data.detail;
        this.form.update_time = this.$moment(this.form.updated_time).format(
          "DD/MM/YYYY HH:MM:SS"
        );

        this.sourceBranch = [
          {
            label: "ชื่อสาขา",
            value: this.form.request_branch_name,
          },
          { label: "รหัส", value: this.form.request_branch_code },
          { label: "ประเภท", value: this.form.branch_type_name },
          { label: "ผู้ส่ง", value: this.form.created_by },
          {
            label: "วันที่ส่งออก",
            value: this.$moment(this.form.created_time).format(
              "DD/MM/YYYY HH:MM:SS"
            ),
          },
        ];

        this.items = res.data.detail.products.map((product, index) => ({
          ...product,
          id_sort: index,
        }));

        this.allItem = this.items;
        this.filteredItems = [...this.allItem];
        this.rows = this.allItem.length;
        this.isLoading = false;
      }

      this.isBusy = false;
    },
    handleSearch() {
      const searchText = this.filter.search.toLowerCase();
      if (searchText) {
        this.filteredItems = this.allItem.filter((item) =>
          item.product_name.toLowerCase().includes(searchText)
        );
      } else {
        this.filteredItems = [...this.allItem];
      }
      this.filter.page = 1;
      this.updateDisplayedItems();
    },

    pagination(val) {
      this.filter.page = val;
      this.updateDisplayedItems();
    },
    handleChangeTake() {
      this.filter.page = 1;
      this.updateDisplayedItems();
    },
    updateDisplayedItems() {
      this.isBusy = true;
      const start = (this.filter.page - 1) * this.filter.take;
      const end = start + this.filter.take;
      this.items = this.filteredItems.slice(start, end);
      this.rows = this.filteredItems.length;

      this.isBusy = false;
    },
    openModalaction(choice, item) {
      this.$refs.modalAction.show(choice, item);
    },
    updateProduct(val) {
      this.isEdit = true;
      this.productRequest.push(val);

      let i = this.allItem.findIndex((item) => item.id == val.id);
      if (i != -1) {
        this.allItem[i].rqp_action_type_id = val.rqp_action_type_id;
        this.allItem[i].product_action = val.rqp_action_type_id
          ? "Approve"
          : "Reject";
        this.allItem[i].headquarters_note = val.headquarters_note;
        this.filteredItems = [...this.allItem];
      }
      this.filter.page = 1;
      this.updateDisplayedItems();
    },
    async confirmProductRequest() {
      this.isLoading = true;
      if (this.form.rqp_status_id == 3) {
        window.location.href = "/request-purchase";
        return;
      }
      let paylaod = {
        rqp_id: this.form.rqp_id,
        products: [...this.productRequest],
      };
      const res = await this.axios.put(`/rqp/ConfirmProductRequest`, paylaod);
      if (res.data.result === 1) {
        this.isLoading = false;
        this.successAlert().then(function () {
          window.location.href = "/request-purchase";
        });
      } else {
        this.isLoading = false;
        this.errorAlert(res.data.message);
      }
    },
    cancel() {
      if (this.isEdit)
        this.confirmAlert({
          message: "คุณต้องการยกเลิกการทำรายการหรือไม่?",
        }).then((val) => {
          if (val.isConfirmed) {
            this.$router.push("/request-purchase");
          }
        });
      else {
        this.$router.push("/request-purchase");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.text-label {
  width: 100px;
}
.f-bold {
  font-weight: bold;
}

.text-light-gray {
  color: #9b9b9b;
}

textarea[disabled] {
  background-color: whitesmoke !important;
}

.img-size {
  width: 60px;
  height: 60px;
}

.text-link {
  text-decoration: underline;
  cursor: pointer;
}
</style>
